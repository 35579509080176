.suggestions-dropdown {
  z-index: 1000;
  color: rgba(33, 37, 41, 1);
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  position: absolute;
}

.suggestion-item {
  z-index: 1001;
  padding: 0.5rem 1rem;
  color: rgba(33, 37, 41, 1);
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    background-color: rgba(237, 240, 255, 1);
  }
  &[aria-selected="true"] {
    background-color: rgba(237, 240, 255, 1);
  }
}

.suggestion-item:focus {
  border: 1px solid #5E6FCF;
}
